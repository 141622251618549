* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
}
a {
  text-decoration: none !important;
}
.main_wrapper {
  width: 100%;
  float: left;
  display: block;
}
.icons-main-div-head-1 {
  margin: 10px 0px;
}
.chat_message_img img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
/*Header*/
.main_header {
  width: 100%;
  float: left;
  display: block;
  background-color: #ffffff;
  padding: 20px 0;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
}
.dropdown-item:active {
  background-color: transparent !important;
}
.logo_jtc {
  cursor: pointer;
}
.logo_car {
  cursor: pointer;
}
.nav-item a.active {
  display: none !important;
  border: none !important;
}
.nav-item a.inactive {
  display: block !important;
  color: #000;
  font-weight: 600;
  line-height: 26px;
  font-size: 18px;
  border-bottom: 1px solid #0000002e;
  padding-bottom: 5px;
  text-align: left !important;
  padding: 10px 12px;
}
.navbar-expand-lg {
  background-color: #fff !important;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.15) !important;
  padding: 20px 0 !important;
}
.navbar-nav {
  align-items: center;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0 !important;
}
.navbar-expand-lg .navbar-nav {
  gap: 8px;
}
.fav-main-div .main_wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.fav-main-div .main_wrapper .filer_heading .container {
  width: 80% !important;
  max-width: unset !important;
}
.fav-main-div .main_wrapper .filer_heading .container .colsm_detil .row {
  row-gap: 15px;
}
.fav-main-div
  .main_wrapper
  .filer_heading
  .container
  .colsm_detil
  .row
  .col-sm-4 {
  width: 25%;
}
.navbar-collapse .navbar-nav .nav-item {
  margin-top: 0 !important;
}
.dropdown-toggle::after {
  display: none !important;
}
.Ad_del_stat {
  margin: 0;
  color: red;
  font-size: 22px;
  font-weight: 700;
}
.dropdown-item {
  display: block !important;
  color: #000 !important;
  font-weight: 600 !important;
  line-height: 26px !important;
  font-size: 18px !important;
  padding: 10px 12px !important;
}
.dropdown-menu {
  --bs-dropdown-min-width: unset !important;
  top: px !important;
  left: -90px !important;
  position: absolute !important;
  background-color: #fff !important;
  list-style: none !important;
  padding: 8px 8px !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35) !important;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35) !important;
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35) !important;
  border-radius: 5px !important;
}
.cell_btn {
  background-color: #000 !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  height: 46px !important;
  line-height: 46px !important;
  text-align: center !important;
  width: 120px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  gap: 5px !important;
}
.cell_btn_Edit {
  background-color: #000 !important;
  border-radius: 6px !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  height: 46px !important;
  line-height: 46px !important;
  text-align: center !important;
  width: 140px !important;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  gap: 5px !important;
}
.store-container {
  width: 100% !important;
  max-width: unset !important;
}
.search_page {
  width: 100%;
  float: left;
  display: block;
}
.search_page .search_center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.search_page .search_center h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}
.search_page .search_center .search_input {
  width: 400px;
  float: left;
  display: block;
  padding: 20px;
  background-color: #d9d9d9;
  border: 0;
  font-size: 20px;
  color: #000000;
}
.search_page .search_center .search_input:focus {
  border: 0;
  outline: 0;
}
.search_page .search_center .search_input:focus {
  border: 0;
  outline: 0;
}
.search_page .search_center .search_btn {
  float: left;
  border: 0;
  padding: 20px;
  background-color: #d9d9d9;
  font-size: 20px;
  margin-left: 5px;
  font-weight: 600;
}
.search_page .search_center select {
  float: left;
  background-color: #d9d9d9;
  border: 0;
  height: 70px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
}
.search_page .search_center select:focus-visible {
  outline: 0;
}
.search_page .search_box {
  width: 100%;
  float: left;
  display: block;
}
.nav-item {
  padding-left: 8px;
}
.logo_car {
  padding-left: 5px;
}
.nav-link {
  border: none !important;
}
.profile_collapse_list {
  cursor: pointer;
}
.img-img {
  width: 22px;
}
li {
  list-style: none !important;
  margin-top: 10px !important;
}
.regiter_year {
  padding-left: unset !important;
}
.regiter_year li {
  margin-top: 15px !important;
  font-weight: 500 !important;
  color: #3e3e3e !important;
}
.overview_heading {
  font-size: 30px !important;
  line-height: 32px !important;
  font-weight: 600 !important;
}
.book_free {
  color: #f75d34 !important;
  font-weight: bold !important;
  font-size: 20px !important;
  display: block !important;
  margin-top: 10px !important;
}
.label-location {
  margin-bottom: 0.5rem !important;
}
.type_input:focus {
  border: 2px solid black !important;
}
.Registration-Year-input:focus {
  border: 2px solid black !important;
}

.form-control-type_input {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.search_dash_div {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}
.p_tag_for {
  height: 100vh;
}
.search_input_dash {
  width: 400px;
  float: left;
  display: block;
  padding: 20px;
  background-color: #d9d9d9;
  border: 0;
  font-size: 20px;
  color: #000000;
}
.benefit_modal {
  font-size: 15px;
  font-weight: 600;
}
.term_cond_div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.form-control-type_input:focus {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #86b7fe !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%) !important;
}
.googleLoginButt {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}
.modal-google {
  background-color: #000000b5;
}
.home-main-div {
  height: 100% !important;
}
/*filer_heading*/
.filer_heading {
  width: 100%;
  float: left;
  display: block;
  padding: 50px 0 0 0;
}
.tabs_main {
  margin-bottom: 50px !important;
}
/* .main_wrapper .filer_heading .container .colsm_detil .row {
  padding-bottom: 50px;
} */
.main-filter-row {
  padding-bottom: 50px;
}
.filer_heading .filter_circel {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  background-color: #fff10f;
  display: block;
  float: left;
  margin-right: 10px;
  margin-top: 5px;
}
.filer_heading .detals_heading {
  font-size: 24px;
  margin-bottom: 40px;
}
.filer_heading .question_mark {
  font-size: 16px;
  background-color: #d9d9d9;
  border-radius: 100px;
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 8px;
  color: #000000;
  margin-left: 5px;
}
.imgclassName {
  width: 100% !important;
  height: 180px !important;
  object-fit: cover !important;
  border-bottom: 1px solid #eee !important;
  border-radius: 8px 8px 0px 0px !important;
}
.carousel {
  padding-bottom: 34px;
}
.carousel-indicators [data-bs-target] {
  height: 10px !important;
  border-radius: 50% !important;
  width: 10px !important;
  background-color: black !important;
}
.carousel-indicators {
  gap: 7px !important;
  margin-bottom: 0 !important;
}
.mt-3 {
  margin-top: 0 !important;
}
.main_wrapper .filer_heading .container .colsm-12 .row .col-sm-9 .row {
  row-gap: 15px !important;
}
.main_wrapper .text-center .container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}
.main_wrapper .text-center .container .search_page .search_center {
  position: unset !important;
  transform: unset !important;
}
a {
  cursor: pointer;
}
.location-name-head p {
  font-size: 18px;
  font-weight: 500;
}
.input {
  /* Reset. */
  cursor: pointer;
  border: unset;
  /* Style */
  width: 100%;
  max-width: 140px;
  padding: 0.75rem;
  border: none !important;
  /* Arrow */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}
.home-butt-div {
  display: flex;
}
.home-butt-div .input {
  width: 140px !important;
  color: black !important;
}
.heart-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.heart-name h5 {
  margin: 0px !important;
}
.main-form-home h1 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}
.heading-price-money {
  font-weight: 700;
  font-size: 16px;
}

.search-input {
  width: 400px;
  float: left;
  display: block;
  padding: 20px;
  background-color: #d9d9d9;
  border: 0;
  font-size: 20px;
  color: #000000;
}
.input {
  /* Reset. */
  border: unset;
  float: left;
  background-color: #d9d9d9;
  border: 0;
  height: 70px;
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
  /* Style */
  width: 100%;
  max-width: 140px;
  padding: 0.75rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* Arrow */
  appearance: none;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23131313%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.65rem auto;
}
select {
  border: 0px;
  outline: 0px;
}
input {
  border: 0px;
  outline: 0px;
}
.form-input {
  align-items: center;
  display: flex;
  justify-content: center;
}
.search-input-butt {
  float: left;
  border: 0;
  padding: 20px;
  background-color: #d9d9d9;
  font-size: 20px;
  margin-left: 5px;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
}
/*accordian*/
.accordion-button {
  background-color: var(--bs-accordion-active-bg) !important;
}
.filer_heading .accordion-button:focus {
  box-shadow: none;
  background-color: var(--bs-accordion-active-bg) !important;
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0
    var(--bs-accordion-border-color) !important;
  color: #000;
}
.custom_accordian {
  margin-bottom: 12px;
}
.form-check-input {
  box-shadow: unset !important;
}
.filer_heading .custom_accordian .accordion-button {
  font-size: 18px;
  color: #000000;
  font-weight: 600;
}
.vehicle-detail-p {
  margin: 0;
}
.filer_heading .custom_accordian .accordion-body {
  padding: 10px 0px 10px 10px;
  height: 100% !important;
}

.filer_heading .custom_accordian .accordion-body label {
  font-size: 15px;
  font-weight: 600;
}
.filer_heading .custom_accordian .accordion-item {
  border: 1px solid #d8d8d8;
  margin-top: 20px;
  border-radius: 8px !important;
}
.filer_heading .custom_accordian .accordion-item:nth-child(1) {
  margin-top: 0px;
}
.cars_deal .likes_btn {
  position: absolute !important;
  right: 10px !important;
  font-size: 22px !important;
  color: red !important;
  background: white;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 32px;
  top: 8px;
}
.noresult-div {
  height: 63vh;
}
.filer_heading .cars_deal {
  width: 100%;
  float: left;
  display: block;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
}
.filer_heading .cars_deal .imgClass {
  border-radius: 8px 8px 0 0;
  width: 100%;
}
.filer_heading .cars_deal .cars_deal_wrap {
  padding: 20px;
  position: relative;
}
.filer_heading .cars_deal .cars_deal_wrap h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
}
.filer_heading .cars_deal .cars_deal_wrap h4 {
  font-size: 22px;
  font-weight: bold;
  line-height: 27px;
}
.filer_heading .cars_deal .cars_deal_wrap p {
  font-size: 16px;
  line-height: 26px;
}
.filer_heading .cars_deal .cars_deal_wrap .regular_off {
  font-size: 14px;
  line-height: 22px;
  color: #f00;
  font-weight: 600;
  background-color: #e7e7e7;
  padding: 6px 11px;
  border-radius: 5px;
  display: inline-block;
}
.filer_heading .cars_deal .cars_deal_wrap span {
  font-size: 12px;
  color: #fff;
  background-color: #000000;
  padding: 8px 10px;
  border-radius: 5px;
  margin-left: 10px;
}
.filer_heading .cars_deal .cars_deal_wrap h4 img {
  width: 20px;
  margin-right: 6px;
}
.col-sm-3 {
  display: flex !important;
  flex-direction: column !important;
  column-gap: 54px !important;
  row-gap: 18px !important;
}
.row .col-sm-4 .cars_deal .cars_deal_wrap h5 {
  cursor: pointer !important;
}
/* footer*/
.main_footer {
  width: 100%;
  float: left;
  display: block;
  padding: 20px 0;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
  -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
  bottom: 0;
}
.search_page .search_center .search_btn i {
  float: left;
  margin: 7px 10px 0 0;
}
.main_footer .footer_list {
  padding: 0;
  list-style: none;
  text-align: right;
}
.main_footer .footer_list li a {
  padding: 0;
  list-style: none;
  text-align: right;
  color: #7d7c7c;
}
/*Login PopUp*/
.modal_body_content .body_popup {
  padding: 30px;
}
.modal_body_content .body_popup h1 {
  font-size: 36px;
  line-height: 42px;
  font-weight: bold;
}
.modal_body_content .body_popup .aroow_down {
  width: 50px;
  background-color: #fff;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 33%);
  -webkit-box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 33%);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  padding: 12px;
  border-radius: 100px;
  display: block;
  margin: 30px auto 0;
}
.modal_body_content .body_popup .google_btn {
  width: auto;
  display: inline-block;
  margin-right: 10px;
  border: 0;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  padding: 12px 20px;
}
.modal_body_content .body_popup .google_btn img {
  width: 30px;
}
.modal_body_content .body_popup h1 span {
  color: #ff4b26;
}
.modal_body_content .body_popup .close_button {
  position: absolute;
  right: 20px;
  top: 20px;
}
.modal_body_content .body_popup .close_button img {
  width: 25px;
}
.main_footer .container .row .col-sm-6 {
  display: flex;
  justify-content: center;
  padding-left: 40px;
  align-items: center;
}
.main_footer .container .row .col-sm-6-1 {
  display: flex;
  justify-content: flex-end !important;
  padding-left: 40px;
  align-items: center;
}
.google-butt-head {
  padding-top: 22px !important;
}
.google-butt {
  width: auto !important;
  display: inline-block !important;
  margin-right: 10px !important;
  border: 0 !important;
  padding: 12px 20px !important;
  box-shadow: 0px 0px 13px 0px rgb(0 0 0 / 33%) !important;
  font-size: 16px !important;
  background-color: #ececec !important;
}
.signin-butt-google {
  width: auto;
  display: inline-block;
  margin-right: 10px;
  border: 0;
  box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  -webkit-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  -moz-box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.33);
  padding: 12px 20px;
  margin-top: 1.5rem !important;
}
.signin-butt-google-image {
  width: 30px;
  margin-right: 4px;
}
.modal-google .modal-dialog {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.google-block-icon {
  color: red;
  font-size: 30px;
}
.modal_header_google {
  background-color: #000000b5;
}
.google-modal-block {
  font-size: 18px;
  font-weight: 700;
  margin: 0;
}
.modal_header_google {
  display: flex !important;
  justify-content: center !important;
}
.modal_header_google .modal-dialog {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0 !important;
}
/* Details page */
.colsm_detil .detail_cars_cvt {
  width: 100%;
  float: left;
  display: block;
  padding: 20px 30px;
}
.colsm_detil .detail_cars_cvt .cvt_heding {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.colsm_detil .detail_cars_cvt .cvt_heding span {
  font-size: 12px;
  color: #fff;
  background-color: #000000;
  padding: 8px 10px;
  border-radius: 5px;
  margin-left: 10px;
}
.colsm_detil .detail_cars_cvt .cvt_heding span img {
  width: 20px;
  margin-right: 6px;
}
.colsm_detil .detail_cars_cvt .detail_view_benifit {
  font-size: 22px;
  font-weight: 600;
  margin: 0;
}
.colsm_detil .detail_cars_cvt .detail_view_benifit a {
  font-size: 22px;
  color: #0f83e7;
  text-decoration: none !important;
  margin-left: 8px;
  font-weight: 600;
}
.colsm_detil .detail_cars_cvt .detail_view_benifit a:hover {
  color: #0f83e7 !important;
}
.visit-store-link {
  font-size: 22px;
  color: #0f83e7;
  text-decoration: none !important;
  font-weight: 600;
}
.price-main-div-head h5 {
  margin: 0;
}
.visit-store-link a:hover {
  color: #0f83e7 !important;
}

.colsm_detil .detail_cars_cvt .detail_regular_price {
  margin-top: 10px;
  font-size: 14px;
  line-height: 22px;
  color: #f00;
  font-weight: 600;
  background-color: #e7e7e7;
  padding: 6px 11px;
  border-radius: 5px;
  display: inline-block;
}
.colsm_detil .detail_cars_cvt .detail_ullist {
  display: flex;
  padding: 20px 0 20px 14px;
  border-bottom: 1px solid #00000038;
}
.colsm_detil .detail_cars_cvt .detail_ullist li a {
  color: #000000;
  font-size: 14px;
  text-decoration: underline !important;
  font-weight: 500;
  margin-right: 30px;
}
.colsm_detil .detail_cars_cvt .price_home_test ul {
  padding: 0;
  list-style: none;
}
.colsm_detil .detail_cars_cvt .price_home_test ul li a {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
}
.colsm_detil .detail_cars_cvt .price_home_test ul li {
  margin-bottom: 10px;
}
.colsm_detil .detail_cars_cvt .price_home_test ul li img {
  width: 18px;
  margin: 0 5px 0 0;
}
.colsm_detil .detail_cars_cvt .price_home_test .chat_with {
  width: 200px;
  display: block;
  background-color: #d9d9d9;
  text-align: center;
  padding: 12px;
  color: #000000;
  font-weight: 600;
  font-size: 18px;
}
.colsm_detil .detail_cars_cvt .detail_step {
  width: 100%;
  float: left;
  display: block;
}
.colsm_detil .detail_cars_cvt .detail_step img {
  width: 50px;
}
.colsm_detil .detail_cars_cvt .detail_step h3 {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 0;
}
.colsm_detil .detail_cars_cvt .detail_step p {
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
}
.colsm_detil .detail_bottom {
  background-color: #e3f4ff;
  float: left;
  width: 100%;
}
.main_details {
  width: 100%;
  float: left;
  display: block;
  border-radius: 10px;
  display: block;
  box-shadow: 0px 0px 71px -39px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 0px 0px 71px -39px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 0px 0px 71px -39px rgba(0, 0, 0, 0.41);
}
.profile-head .main_wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.detail_tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #000;
  border-radius: 50px;
  font-weight: 600;
}
.detail_tabs .nav-pills .nav-link {
  background-color: #f1f6fa;
  border-radius: 50px;
  color: #000;
  font-weight: 600;
  margin-left: 8px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.7);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.7);
}
.col-sm-5 {
  padding-top: 69px !important;
}
.slick-initialized .slick-prev {
  display: none !important;
}
.slick-initialized .slick-next {
  right: 19px;
}
.slick-initialized .slick-prev {
  display: block !important;
  left: 0px;
  z-index: 999;
  margin-left: 10px !important;
}
.d-block {
  cursor: grab;
}
.slick-next:before,
.slick-prev:before {
  font-size: 30px;
  color: black;
}
.accordion-body {
  background-color: #f4f4f4 !important;
}
.slick-dots li button:before {
  font-size: 10px !important;
}
/*tabs*/
.tabs_main {
  width: 100%;
  float: left;
  display: block;
  padding: 15px;
  box-shadow: 0px 0px 71px -39px rgba(0, 0, 0, 0.41);
  -webkit-box-shadow: 0px 0px 71px -39px rgba(0, 0, 0, 0.41);
  -moz-box-shadow: 0px 0px 71px -39px rgba(0, 0, 0, 0.41);
}
.tabs_main .overview_heading {
  font-size: 30px;
  line-height: 32px;
  font-weight: 600;
}
.tabs_main .list_tabs {
  list-style: none;
}
.detail_tabs .tabs_main .regiter_year {
  list-style: none;
  padding: 0;
}
.detail_tabs .tabs_main .regiter_year li img {
  width: 22px;
  float: left;
  margin: 2px 2px 0 0;
}
.detail_tabs .tabs_main .regiter_year li {
  font-size: 16px;
  font-weight: 500;
  color: #3e3e3e;
  margin-top: 15px;
  display: block;
}
.detail_tabs .accordion-item {
  border: 0;
}
.detail_tabs .accordion-body {
  padding: 0;
}
.detail_tabs .tabs_main .book_free {
  color: #f75d34;
  font-weight: bold;
  font-size: 20px;
  display: block;
  margin-top: 10px;
}
.overview-tab .mt-3 .active .nav-tabs {
  justify-content: flex-start !important;
}
.overview-tab .mt-3 .active .nav-tabs .nav-item button {
  height: 35px !important;
  width: 100px !important;
  background-color: #f1f6fa !important;
  border-radius: 50px !important;
  color: #000 !important;
  font-weight: 600 !important;
  margin-left: 8px !important;
  display: flex !important;
  justify-content: center !important;
  font-size: 16px !important;
  padding: 0 !important;
  align-items: center !important;
  box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 70%) !important;
}
.overview-tab .mt-3 .active .nav-tabs .nav-item button:hover {
  background-color: #f1f6fa !important;
  color: #000 !important;
  border: none !important;
}
.footer-main-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.footer-part1 p {
  font-size: 14px;
}
.footer-part2 p {
  margin: 0;
  font-size: 14px;
}
.overview-tab .mt-3 .active .nav-tabs .nav-item button.active {
  background-color: #000 !important;
  border-radius: 50px !important ;
  font-weight: 600 !important;
  color: white !important;
}
.tab-content {
  margin-top: 0 !important;
}
.dashboard-head .main_wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
/*post-data page css*/
.data_page {
  width: 100%;
  float: left;
  display: block;
  padding: 50px 0;
}
.data_page .data_Category .selct_hding {
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}
.data_page .data_Category ul {
  list-style: none;
  padding: 0;
  display: flex;
}
.data_page .data_Category ul li {
  font-size: 20px;
  margin-right: 15px;
}
.data_page .data_Category ul li a {
  font-size: 20px;
  color: #000000;
}
.data_page .data_Category ul .changes_third {
  font-weight: bold;
  border-bottom: 2px solid #000;
}
.data_page .data_Category .brnd_label {
  width: 50%;
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}
.required {
  color: red;
  font-size: 26px;
}
.data_page .data_Category .type_input {
  width: 50%;
  display: block;
  font-size: 18px;
  border: 1px solid #0000003d;
  line-height: 26px;
  padding: 15px;
  border-radius: 6px;
  margin-top: 16px;
  background-color: white;
}
.profile_page .container .profile_wraper .row .col-lg-3{
  width: 20% !important;
}
.profile_page .container .profile_wraper .row .col-lg-9{
  width: 80% !important;
}
.deactivated_chat p{
  font-size: 15px;
  font-weight: 800;
  color: red;
  margin: 0;
}
.deactive_chat_admin p{
  margin: 0;
  font-size: 20px;
    font-weight: 600;
    color: red;
}
.profile_page .container .profile_wraper .row .col-lg-9 ul {
  margin: 0 !important;
}
.data_page .data_Category .type_input:focus {
  box-shadow: none;
}
.data_page .data_Category .list_fuel li a {
  border: 1px solid #0000003d;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 7px;
}
.data_page .data_Category .mntion_key {
  font-size: 14px;
  padding: 5px;
}
.mntion_key-image {
  font-size: 14px;
  color: red;
}
.data_page .data_Category .upload_tabs .tabs_pills li {
  width: 25%;
  display: block;
  text-align: center;
}
.data_page .data_Category .upload_tabs .tabs_pills li .nav-link {
  color: #000;
  font-size: 20px;
  width: 100%;
  text-align: center;
}
.data_page .data_Category .upload_tabs .tabs_pills li .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  border-bottom: 4px solid #000000;
  border-radius: 0;
  width: 100%;
  text-align: center;
}
.data_page .data_Category .review_detail .will_code {
  font-size: 18px;
  line-height: 24px;
}
.data_page .data_Category .review_detail .verify_hding {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}
.data_page .data_Category .review_data .profile_upload {
  float: left;
  margin-right: 10px;
}
.data_page .data_Category .review_data .type_width {
  width: 50% !important;
}
.main_border {
  border: 1px solid #d1d1d3;
  border-radius: 5px;
  padding: 20px 30px;
  width: 100%;
  float: left;
  display: block;
}
.profile-photo {
  border-radius: 50%;
}
.border_width_ul {
  border-bottom: 1px solid #d1d1d3;
  width: 50%;
}
.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}
.mt-5 .inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}
input[type="date"]::before {
  width: 100%;
}
.delete-image-icon {
  color: black;
  font-size: 19px !important;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -11px;
  background-color: black;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
}
.delete-part-image-icon {
  color: black;
  font-size: 19px !important;
  cursor: pointer;
  position: absolute;
  top: -10px;
  right: -11px;
  background-color: black;
  color: white;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 5px;
}
.chat_icon_image {
  height: 20px;
  width: 20px;
}
.mt-5 .rmdp-container input {
  width: 100% !important;
  display: block !important;
  font-size: 18px !important;
  border: 1px solid #0000003d !important;
  line-height: 26px !important;
  padding: 26px 15px !important;
  border-radius: 5px !important;
}
.css-b62m3t-container:hover {
  border-color: hsl(0, 0%, 80%) !important;
}

.css-t3ipsp-control {
  border-color: hsl(0, 0%, 80%) !important;
  box-shadow: none !important;
}
.css-13cymwt-control {
  min-height: unset !important;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
}
.css-qbdosj-Input {
  padding: 0 !important;
  margin: 0 !important;
}
.css-1fdsijx-ValueContainer {
  padding: 0.375rem 5px 0.375rem 0.75rem !important;
}
.css-qbdosj-Input {
  display: none !important;
}
.css-1jqq78o-placeholder {
  margin: 0 !important;
}
.css-1xc3v61-indicatorContainer {
  padding: 0.375rem 0.75rem 0.375rem 5px !important;
  display: unset !important;
}
.mt-5 .rmdp-container {
  width: 100%;
}
.rmdp-calendar {
  width: 308px !important;
}
.rmdp-year-picker {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
}
.month-year-div {
  display: flex !important;
  width: 100% !important;
}
.month-year-div .mt-5 {
  margin-top: 0 !important;
  width: 25% !important;
}
.month-year-div .mt-5 label {
  width: 100% !important;
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}
.month-year-div .mt-5 select {
  width: 95% !important;
}
.buttons input {
  appearance: none;
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid #0000003d;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 7px;
  margin-right: 15px;
}
.buttons input:checked {
  background: #0d6efd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 0px #808080;
  color: white;
}
.buttons input::before {
  content: attr(label);
  text-align: center;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  background-color: #dadada8f !important;
}
.accodion-custom-height {
  border-radius: 0 0 0.375rem 0.375rem !important;
}
.radio--buttons input {
  appearance: none;
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid #0000003d;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 7px;
  margin-right: 15px;
}
.radio--buttons input:checked {
  background: #0d6efd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 0px #808080;
  color: #fff;
}
.paragraph-detail {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
}
.paragraph-detail-vehicle {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 0;
}
.radio--buttons input::before {
  content: attr(label);
  text-align: center;
}
.radio---buttons input {
  appearance: none;
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid #0000003d;
  font-size: 18px;
  padding: 5px 10px;
  border-radius: 7px;
  margin-right: 15px;
}
.cars-fav-deal {
  min-height: 400px !important;
  max-height: 400px !important;
}
.radio---buttons input:checked {
  background: #0d6efd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 0px #808080;
  color: #fff;
}
.radio---buttons input::before {
  content: attr(label);
  text-align: center;
}
#image-radio-buttons {
  height: 100%;
  appearance: none;
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid #0000003d;
  font-size: 18px;
  border-radius: 7px;
  margin-right: 15px;
  width: 100%;
}
.outer-div-vehicle {
  position: relative;
  width: 100%;
  display: block;
}
#image-radio-buttons label {
  width: 100% !important;
  height: 100%;
  margin-top: -27px;
}
.select-icons-image {
  position: relative;
  z-index: 2;
  top: 28px;
  right: -5px;
}
.image-parts-radio-buttons {
  height: 100%;
  width: 100%;
  appearance: none;
  cursor: pointer;
  transition: all 0.1s;
  border: 1px solid #0000003d;
  font-size: 18px;
  border-radius: 7px;
  margin-right: 15px;
}
.image-parts-radio-buttons label {
  width: 100% !important;
  height: 100% !important;
  margin-top: -27px;
}
#image-radio-buttons input:checked {
  background: #0d6efd;
  text-shadow: 0 1px 0px #808080;
  color: #fff;
}
.image-parts-radio-buttons input:checked {
  background: #0d6efd;
  text-shadow: 0 1px 0px #808080;
  color: #fff;
}
#image-radio-buttons input::before {
  content: attr(label);
  text-align: center;
}
.image-parts-radio-buttons input::before {
  content: attr(label);
  text-align: center;
}
.data_page .data_Category .brnd_label {
  margin-bottom: 8px;
}
/* loader css */
.loader-main-div {
  width: 100%;
  display: block;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 9999;
}
.loader-second-div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader-spinner {
  z-index: 99999;
  width: 60px;
  height: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* post ad location css */
.location-div {
  display: flex;
  width: 45%;
  justify-content: space-between;
  margin-top: 30px;
}
.location-data {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0;
}
.main-all-div-post {
  width: 100%;
  display: flex;
  justify-content: center;
}
.main_border {
  width: 80%;
}
/* Dashboard CSS */
.dashboard_start {
  width: 100%;
  float: left;
  display: block;
  padding: 50px 0;
}
/*data-post*/
.select_post_form {
  width: 100%;
  float: left;
  display: block;
}
.select_post_form .slct_post_list {
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: center;
}
.select_post_form .slct_post_list li {
  float: left;
  margin-right: 10px;
}
.select_post_form .slct_post_list li:last-child {
  margin-right: 0px;
}
.select_post_form .slct_post_list li a {
  border: 2px solid #000;
  font-size: 20px;
  font-weight: 600;
  color: #e1e1e1;
  background-color: black;
  padding: 10px 10px;
  border-radius: 3px;
  text-align: center;
  width: 200px;
  float: left;
}
.main-section-homePage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.data_Category .sbmit_btn_end {
  width: 20%;
  font-size: 20px !important;
  color: white !important;
  border: none !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  background-color: #295b65;
}
.store-input-field {
  width: 100% !important;
  display: block !important;
  font-size: 18px !important;
  border: 1px solid #0000003d !important;
  line-height: 26px !important;
  padding: 15px !important;
  border-radius: 5px !important;
  margin-top: 16px !important;
  background-color: white !important;
}
.select_post_form .slct_post_list li a:hover {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000;
}
.main_header .btn_toggle {
  outline: 0;
  border: 1px solid #000;
  box-shadow: none;
  padding: 4px 8px;
}
.header-fav {
  color: red;
  font-size: 50px;
}
.main_header .btn_toggle:focus {
  outline: 0;
  border: 1px solid #000;
  box-shadow: none;
  padding: 4px 8px;
}
.nav-tabs .nav-link {
  border-radius: 4px !important;
  background-color: black !important;
  color: #e1e1e1 !important;
  width: 200px !important;
  height: 53px !important;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.nav-tabs .nav-link.active {
  border-radius: 6px !important;
  background-color: #e8e2e2 !important;
  color: black !important;
  width: 200px !important;
  height: 53px !important;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
}
.dash-table {
  margin-bottom: 0px !important;
}
.container .nav-tabs .nav-link.active:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 2px solid #000 !important;
  transition: none;
}
.nav-tabs {
  justify-content: center;
  border: none !important;
  padding: 15px 0px;
  gap: 2px;
}
.container .nav-tabs li button:hover {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 2px solid #000 !important;
  transition: none;
}
.label-location-vehicle {
  margin-bottom: 0.5rem !important;
  width: 50%;
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}
.label-location-input {
  width: 50%;
  display: block;
  font-size: 18px;
  border: 1px solid #0000003d;
  line-height: 26px;
  padding: 15px;
  border-radius: 5px;
}
.label-location-input:focus-visible {
  border: 1px solid #0000003d !important;
}
.mt-2 .upload_tabs .nav-tabs {
  justify-content: flex-start !important;
  border-bottom: 1px solid #dee2e6 !important;
}
.mt-2 .upload_tabs .nav-tabs li {
  width: 25%;
  display: block;
  text-align: center;
}
.postAdd_btn_1 .mt-2 .upload_tabs .nav-tabs .nav-item .nav-link {
  color: #000 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: center !important;
  padding: 12px !important;
}
.postAdd_btn_1 .mt-2 .upload_tabs .nav-tabs .nav-item .nav-link:hover {
  border: none !important;
}
.postAdd_btn_1 .mt-2 .upload_tabs .nav-tabs .nav-item .nav-link.active {
  background-color: transparent !important;
  border-bottom: 4px solid #000000 !important;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: center !important;
  padding: 12px !important;
}
.postAdd_btn_2
  .postAdd-2
  .mt-3
  .mb-4
  .mt-2
  .upload_tabs
  .nav-tabs
  .nav-item
  .nav-link {
  color: #000 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: center !important;
  padding: 12px !important;
}
.postAdd_btn_2
  .postAdd-2
  .mt-3
  .mb-4
  .mt-2
  .upload_tabs
  .nav-tabs
  .nav-item
  .nav-link:hover {
  border: none !important;
}
.postAdd_btn_2
  .postAdd-2
  .mt-3
  .mb-4
  .mt-2
  .upload_tabs
  .nav-tabs
  .nav-item
  .nav-link.active {
  background-color: transparent !important;
  border-bottom: 4px solid #000000 !important;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: center !important;
  padding: 12px !important;
}
.postAdd-3 .mt-3 .mb-4 .mt-2 .upload_tabs .mt-3 .nav-tabs .nav-item .nav-link {
  color: #000 !important;
  background-color: transparent !important;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: center !important;
  padding: 12px !important;
}
.postAdd-3
  .mt-3
  .mb-4
  .mt-2
  .upload_tabs
  .mt-3
  .nav-tabs
  .nav-item
  .nav-link:hover {
  border: none !important;
}
.main-store-head {
  padding: 50px 0px !important;
}
.result-pagination {
  margin-bottom: 0 !important;
}
.custom_accordian {
  margin-bottom: 0 !important;
}
.accordion-main-head {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.postAdd-3
  .mt-3
  .mb-4
  .mt-2
  .upload_tabs
  .mt-3
  .nav-tabs
  .nav-item
  .nav-link.active {
  background-color: transparent !important;
  border-bottom: 4px solid #000000 !important;
  border-radius: 0 !important;
  width: 100% !important;
  text-align: center !important;
  padding: 12px !important;
}
#upload-image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100% !important;
  row-gap: 20px;
  padding-bottom: 15px;
  column-gap: 20px;
}
.upload-parts-image-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100% !important;
  row-gap: 20px;
  column-gap: 20px;
  padding-bottom: 15px;
}
.input-field-image {
  display: none;
}
.input-parts-field-image {
  display: none;
}
.label-upload-image {
  display: flex;
  background-color: #fff;
  height: 86px;
  width: 50%;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 10px;
  border: 3px dashed #295b6569;
}
.label-parts-upload-image {
  display: flex;
  background-color: #fff;
  height: 86px;
  width: 50%;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  border: 3px dashed #295b6569;
}
.label-store-upload-image {
  display: flex;
  background-color: #fff;
  height: 86px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  border: 3px dashed #295b6569;
}
.upload-icon-image {
  width: 100%;
  font-size: 50px;
  color: #295b65;
}
.upload-parts-icon-image {
  width: 100%;
  font-size: 50px;
}
.para-upload-image {
  font-size: 13px;
  font-weight: 700;
  color: #295b65;
  text-transform: uppercase;
}
.no-result-filter {
  height: 50vh;
  display: flex;
  justify-content: center;
  font-size: 22px;
  font-weight: 800;
}
.para-parts-upload-image {
  font-size: 13px;
  font-weight: 700;
  color: #295b65;
  text-transform: uppercase;
}
#upload-images {
  width: 100% !important;
  min-height: 135px;
  max-height: 135px;
  object-fit: cover;
  position: relative;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  border-radius: 7px;
}
.main-all-div-post .main_border .data_Category {
  width: 100%;
}
.upload-part-images {
  width: 100% !important;
  min-height: 135px;
  max-height: 135px;
  object-fit: cover;
  position: relative;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  border-radius: 7px;
}
.upload-store-images {
  min-height: 300px;
  width: 100% !important;
  object-fit: cover;
  position: relative;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
  max-height: 300px;
  border-radius: 7px;
}
/* Cell Button css */
.cell_btn_colapse ul li .cell_btn {
  background-color: #000;
  color: #fff !important;
  line-height: 46px;
  height: 46px;
  width: 120px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 4px;
}
.cell_btn_colapse ul li .cell_btn .plus_icon_cell {
  margin-right: 7px;
}
.cell_btn_colapse ul li .nav-link {
  padding: 0;
}
.Registration-Year-input {
  width: 100% !important;
  display: block !important;
  font-size: 18px !important;
  border: 1px solid #0000003d !important;
  line-height: 26px !important;
  padding: 14px 15px !important;
  border-radius: 5px !important;
}
/*Profile Css*/
.main_header .profile_collapse .profile_collapse_list {
  display: none;
}
.main_header .profile_collapse:hover .profile_collapse_list {
  display: block;
  position: absolute;
  background-color: #fff;
  list-style: none;
  padding: 15px 20px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}
.main_header .profile_collapse {
  margin-left: 10px;
}
.profile_collapse:hover .profile_collapse_list li a {
  color: #000;
  font-weight: 600;
  line-height: 26px;
  font-size: 18px;
}
.profile_collapse:hover .profile_collapse_list li {
  border-bottom: 1px solid #0000002e;
  padding-bottom: 5px;
}
.profile_collapse:hover .profile_collapse_list li:last-child {
  border-bottom: 0;
  padding-bottom: 0px;
}
/*profile_page*/
.profile_page {
  width: 100%;
  float: left;
}
.profile_page .profile_wraper {
  width: 80%;
  padding: 30px;
  margin: 50px auto;
  display: block;
  background-color: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
}

.profile_page .profile_wraper ul {
  list-style: none;
  padding: 0;
}
.profile_page .profile_wraper ul li {
  font-size: 20px;
  padding-bottom: 10px;
}
.nav-item:hover {
  display: block !important;
}
/* --------------------------------FIlter Category css */
#cont-div {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
/* ---------detail tab detail page */
.countryType {
  /* width: 100%; */
  display: block;
  font-size: 18px;
  border: 1px solid #0000003d;
  line-height: 26px;
  padding: 15px;
  border-radius: 5px;
  background-color: white;
}
.label-country-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.label-country-div-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.shipping-div {
  padding: 20px 0px 0px 0px;
}
.price-over-div {
  display: flex;
  gap: 20px;
  width: 80%;
  justify-content: space-between;
}
.price-over-div h6 {
  margin: 0 !important;
}
.price-detail-main {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 1rem;
}

/* --------------dashboard csss--------------- */
.dashboard-image {
  min-height: 150px;
  min-width: 250px;
  max-height: 150px;
  max-width: 250px;

  object-fit: cover;
}
.dashboard-butt {
  width: 165px !important;
  font-size: 20px !important;
  color: #ffffff !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
}
.disableButt {
  border: none;
  cursor: not-allowed !important;
  width: 165px !important;
  font-size: 20px !important;
  color: #ffffff !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  background-color: #dc3545 !important;
  opacity: 0.65 !important;
}
.disableButt-republish {
  border: none;

  cursor: not-allowed !important;
  width: 165px !important;
  font-size: 20px !important;
  color: #ffffff !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  background-color: #198754 !important;
  opacity: 0.65 !important;
}
.disableButt-edit {
  border: none;

  cursor: not-allowed !important;
  width: 165px !important;
  font-size: 20px !important;
  color: #ffffff !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  background-color: #ffc107 !important;
  opacity: 0.65 !important;
}
.dashboard-main-div {
  display: flex;
  justify-content: center;
  width: 100%;
}
.table {
  width: unset !important ;
}
.dashboard_table {
  width: 100%;
  display: flex;
  justify-content: center;
}
.table-data-width {
  min-width: 160px;
  max-width: 170px;
}
/* -------Image slicker css */
.image-slick-main {
  min-height: 400px;
  max-height: 400px;
  object-fit: contain;
}
.image-slick-parts {
  min-height: 400px;
  max-height: 400px;
  object-fit: contain;
}
.image-slick-accesories {
  min-height: 400px;
  max-height: 400px;
  object-fit: contain;
}
/* ----------------Details tab cass */
.Term_Modal {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.Term_Modal .modal-dialog {
  width: 40% !important;
  --bs-modal-width: unset;
  max-width: unset !important;
  margin-top: 0 !important;
}
.details-div {
  width: 50% !important;
  row-gap: 10px !important;
}
.icons-main-div-head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.icons-child-div {
  display: flex;
  align-items: center;
}
.overview-name-data {
  margin-bottom: 0 !important;
}
/* ------profile css */
.package-heading {
  width: 100%;
  display: flex;
  justify-content: center;
}
.logo-icon-detail {
  font-size: 18px;
  margin-right: 10px;
}
.logo-icon-p {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
}
.lable-icon-li {
  display: flex;
  align-items: center;
}
.package-detail-1 {
  /* width: 100%;
  height: 500px;
  background-color: black;
  border-radius: 85px 0 85px 0; */
}
.newdiv {
  width: 100%;
  padding-bottom: 30px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

.scroll-hello {
  height: auto;
  max-height: 154px;
  overflow-y: auto;
}

.product-deal-section .row {
  margin: 0 !important;
}
.product-deal-section .row .col-lg-12 {
  padding: 0 !important;
}
.package-name {
  font-size: 30px;
  font-weight: 800;
  background: rgb(50, 50, 50);
  color: white;
  border-radius: 6px 6px 0px 0px;
  margin: 0;
  padding-top: 10px;
}
.package-Price {
  font-size: 30px;
  font-weight: 800;
  background: rgb(50, 50, 50);
  color: white;
  padding-bottom: 10px;
  /* border-radius: 40px 40px 0px 0px; */
}
.package-detail-11 {
  height: 450px;
  background-color: white;

  align-items: center;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  border-radius: 6px;
  text-align: center;
  width: 100%;
}
.package-details-inner-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  background: #eee;
  border-radius: 6px;
}
.package-butt {
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 6px;
}
.package-description {
  text-align: left;
  font-size: 20px;
  text-indent: -33px;
  padding-left: 40px;
}
.package-check {
  font-size: 30px;
}

.package-butt button {
  width: 90%;
  font-size: 20px !important;
  color: white !important;
  border: none !important;
  padding: 10px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  background-color: #295b65;
}
.showing-para-pagination p {
  margin: 0 !important;
}
.pagination-botttom {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.result-per-page-div {
  display: flex;
  align-items: center;
  gap: 5px;
}
.result-per-page {
  width: 41%;
  display: block;
  font-size: 14px;
  border: 1px solid #0000003d;
  line-height: 26px;
  padding: 4px;
  border-radius: 6px;
  background-color: white;
}
.result-per-page-div p {
  margin: 0 !important;
}
.page-item {
  margin-top: 0px !important;
}
.page-itemactive {
  margin-top: 0px !important;
}
/* --------dashboard modal */
.dashboard-modal {
  background-color: #000000b5;
}
.dashboard-modal .modal-dialog {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-goto {
  display: flex !important;
  background-color: #000 !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-size: 20px !important;
  font-weight: 700 !important;
  height: 46px !important;
  line-height: 46px !important;
  text-align: center !important;
  width: 150px !important;
  align-items: center !important;
  justify-content: center;
}
.modal-backdrop {
  background-color: transparent !important;
}
.modal-head-warning {
  width: 100%;
  display: flex;
  justify-content: center;
}
.modal-body-data {
  width: 100%;
  padding: 20px 10px !important;
}
.modal-body-aid p {
  margin: 0;
  display: flex;
  justify-content: center;
  font-size: 31px;
  font-weight: 800;
  color: #dc3545;
}
.modal-body-radio input {
  margin-right: 5px;
}
#otherOption {
  width: 100%;
  display: block;
  font-size: 18px;
  border: 1px solid #0000003d;
  line-height: 26px;
  padding: 10px;
  border-radius: 5px;

  background-color: white;
}
.store_main_image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.modal-head-para p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}
.modal-body-radio {
  font-size: 20px;
  font-weight: 400;
  padding: 5px 0;
}
.modal-body-button {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.dashboard-modal-body-img {
  height: 100% !important;
  width: 100% !important;
}
.dashboard-popup-img {
  height: 100% !important;
  width: 100% !important;
}
.delete-image-dashboard {
  position: absolute !important;
  color: black;
  font-size: 35px !important;
  cursor: pointer;
  margin-top: -27px;
  right: -9px;
}
.image-modal-dashboard {
  background-color: #363636de;
}
.Term_Modal {
  background-color: #363636de;
}
.image-modal-dashboard .modal-dialog {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-modal-dashboard .modal-dialog {
  width: 100% !important;
  max-width: unset;
  margin: 0;
}
.image-modal-dashboard .modal-dialog .modal-content {
  width: 80%;
  height: 80%;
  object-fit: contain;
}
.delete-image-icon-dashboard {
  color: black;
  font-size: 19px !important;
  cursor: pointer;
  position: absolute;
  top: -17px;
  right: -16px;
  background-color: black;
  color: white;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 5px;
}
.store-country {
  width: 100%;
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}
.favourite-section {
  padding-bottom: 50px;
}
.store-country-select {
  width: 100%;
  display: block;
  font-size: 18px;
  border: 1px solid #0000003d;
  line-height: 26px;
  padding: 15px;
  border-radius: 6px;
  margin-top: 16px;
  background-color: white;
}
.store-div-parts {
  display: flex;
  width: 100%;
  gap: 10px;
}
.store-div-button {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.store-div-sec {
  width: 50%;
}
.store-div-sec input:focus {
  box-shadow: none !important;
  border: var(--bs-border-width) solid var(--bs-border-color);
}
.upload-store-image-grid {
  display: grid;
  width: 100% !important;
  row-gap: 20px;
  column-gap: 20px;
  padding-bottom: 15px;
}
.store-heading h2 {
  font-weight: 700;
  text-align: center;
}
.store-submit {
  width: 20%;
  font-size: 20px !important;
  color: white !important;
  border: none !important;
  padding: 10px !important;
  font-weight: 600 !important;

  border-radius: 6px !important;
  background-color: #295b65;
}
.store-submit-myStore {
  width: 20%;
  font-size: 20px !important;
  color: #295b65 !important;

  padding: 10px !important;
  font-weight: 600 !important;

  border-radius: 6px !important;
  background-color: white;
  border: 2px solid #295b65;
}
/* chat with seller */
.default-message {
  font-size: 30px;
  text-align: center;
  font-weight: 500;
}
.default-message-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.Chat-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.chat-profile-photo {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 2px;
  margin-left: 35px;
  border-radius: 50%;
}
.user-messages.active {
  border: 2px solid blue;
}
.chat-profile-photo-msg {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: -5px;
  margin-left: 35px;
  border-radius: 50%;
}
.Advertise_image {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.chat-side-container {
  margin-top: 2rem;
  width: 30%;
  height: 60vh;
  padding: 10px 0px 10px 10px;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 2rem;

  background-color: #eee;
}

.chat-time {
  font-size: 10px;
}
.message-container {
  display: flex;
  justify-content: end;
}
.message-start-container {
  display: flex;
  justify-content: start;
  gap: 25px;
  flex-direction: column;
}
.end-message {
  width: auto;
  background: #eee;
  padding: 5px 20px;
  border-radius: 10px;
  margin-right: 11px;
}
.send-icon {
  position: absolute;
  right: 20px;
}

.start-message {
  display: flex;
  flex-direction: column;
  width: auto;
  background: #eee;
  padding: 5px 20px;
  border-radius: 10px;

  max-width: 350px;
  word-wrap: break-word;
}
.right-side-main-div {
  height: 100%;
}
.main-message-container {
  overflow-y: scroll;
  height: 70%;
  padding: 10px;
}
.receiveMessage p {
  width: fit-content;
  background: #ccc;
  padding: 2px 10px;
  border-radius: 8px 0px 8px 8px;
  max-width: 300px;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word !important;
  margin: 0;
}
.send_message_input:focus {
  box-shadow: none !important;
}
.right_message {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.left_right_msg_div {
  width: 100%;
}
.date_header {
  display: flex;
  justify-content: center;
}
.message_body_both {
  display: flex;
  gap: 15px;
}

.chat_del_icon {
  margin-left: auto;
}
.chat_del_icon_hello{
  margin-left: 0;
}
.view_term {
  display: flex;
}
.message_body_both_hello {
  display: flex;
  gap: 15px;

  justify-content: flex-end;
}
.chat_status {
  width: 100%;
  display: flex;
  justify-content: center;
}
.chat_status p {
  margin: 0;
  font-size: 22px;
  color: red;
  font-weight: 600;
}
.date_header p {
  color: #1d1d1da1;
  margin: 0;
  background-color: #ededed;
  padding: 3px 15px 3px 15px;
  border-radius: 14px;
  font-size: 15px;
  font-weight: 600;
}
.left_message {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.expire_div{
  display: flex;
  gap: 6px;
}
.expired_value{
  display: flex;
}
.expired_value p{
  color: red;
}
.chat_msg_name_show {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  gap: 15px;
}
.chat_msg_name_show_rec {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}
.chat_msg_name {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.chat_msg_name_rec {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
}

.sentMessage p {
  width: fit-content;
  background: #ccc;
  padding: 2px 10px;
  border-radius: 0 8px 8px 8px;
  max-width: 300px;
  font-size: 16px;
  font-weight: 400;
  word-wrap: break-word !important;
  margin: 0;
}
.chat_main_msg_div {
  display: flex;
}
.span_message {
  font-size: 14px;
  align-items: flex-start;
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #6d6d6d9c;
}
.user-messages {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 70px;
  /* box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35); */
  margin-bottom: 1rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 15px;
  padding-left: 2rem;
  padding-right: 10px;
  background: #fff;
}
.chat-right-side-container {
  width: 60%;
  margin-top: 2rem;
  height: 60vh;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 2rem;
  position: relative;
}
.chat-right-side-default-container {
  width: 60%;
  margin-top: 2rem;
  height: 60vh;
  border: 1px solid #eee;
  border-radius: 10px;
  margin-bottom: 2rem;
  position: relative;
}
.chat-input-div {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
}
.chat-input {
  width: 100%;
  height: 100%;
  background: #eee;

  padding: 0px 15px;
  padding-right: 40px;
  border-radius: 0 0 7px 7px;
}
.chat_ad_one {
  display: flex;
  align-items: center;
  gap: 5px;
}
.chat_ad_img {
  display: flex;
  align-items: center;
  position: relative;

  gap: 5px;
}
.chat_ad_img p {
  font-weight: 600;
  margin: 0;
}
.back_icon_chat {
  cursor: pointer;
  font-size: 17px;
}
.chat-messages {
  width: 100%;
  height: 15%;
  border-bottom: 1px solid #eee;
  padding: 1rem;
  background: #eee;
  display: flex;
  align-items: center;
  gap: 10px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  justify-content: space-between;
}
.Chat_ad_title {
  display: flex;
  font-weight: 600;
  gap: 5px;
}
.chat_ad_prod {
  margin: 0;
  font-weight: 600;
}
.chat_ad_adver {
  margin: 0;
  font-weight: 600;
}
/* ---------main store css */
.main-store-div-head {
  padding-bottom: 50px;
}
.main-store-div-head .container {
  width: 80% !important;
  max-width: unset !important;
}
.main-store-div-head .container .colsm_detil .row {
  row-gap: 15px;
}
.main-store-div-head .container .colsm_detil .row .col-sm-4 {
  width: 25%;
}
.main-store-page {
  margin-bottom: 120px;
}
.detail_nav_section {
  width: 100%;
  float: left;
  display: block;
  padding: 20px 20px;
  box-shadow: 0 2px 10px -7px #000000;
  background-color: #8fa75dbd;
}
.detail_nav_section .store_list_ul {
  display: flex;
  list-style: none;
  padding: 0;
}
.detail_nav_section .store_list_ul li {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  margin-right: 10px;
  text-align: center;
  border-right: 1px solid #6e6e6e;
  align-items: center;
  display: flex;
  margin-top: 0 !important;
  padding-right: 10px;
}
.contact_sport {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.detail_nav_section .contact_sport h2 {
  font-size: 22px;
  font-weight: 700;
}
.detail_nav_section .store_list_ul li:last-child {
  margin-right: 0;
  border-right: 0;
}
.detail_nav_section .contact_sport .sociel_list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.detail_nav_section .contact_sport .sociel_list li {
  margin-right: 15px;
}
.contact_sport_dflex {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.detail_nav_section .row .col-lg-3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.detail_nav_section .row .col-lg-7 ul {
  margin: 0;
}
.detail_nav_section .row .col-lg-2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contact_sport h4 {
  margin: 0;
}
.contact_sport ul {
  margin: 0;
}
.logo-image-store {
  max-height: 100px;
  min-height: 100px;
  object-fit: contain;
}
.detail_nav_section .row {
  display: flex !important;
  width: 100% !important;
  margin: 0 !important;
}
.logo-image-div {
  width: 15% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.main-store-adress {
  width: 65% !important;
  justify-content: center !important;
  display: flex !important;
  align-items: center !important;
}
.main-store-contact {
  width: 20% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

/* -------------Profile package */
.package-selected-name {
  font-size: 30px;
  font-weight: 800;
  background: red;
  color: white;
  border-radius: 6px 6px 0px 0px;
  margin: 0;
  padding-top: 10px;
}
.package-selected-Price {
  font-size: 30px;
  font-weight: 800;
  background: red;
  color: white;
  padding-bottom: 10px;
}
.result-pagination {
  justify-content: center !important;
}

.inbox_first_head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
  height: 15%;
  padding-right: 10px;
}
.inbox_data_name {
  height: 85%;
  overflow-y: scroll;
  padding-right: 10px;
}
.inbox_first_head h3 {
  margin: 0 !important;
}
.inbox_second_head {
  display: flex;
  align-items: center;
  gap: 10px;
}
.inbox_second_head p {
  margin: 0 !important;
  font-size: 18px;
  font-weight: 500;
}
.profile_package_li {
  display: flex;
  gap: 4px;
  align-items: center;
}
.profile_package_li p {
  margin: 0 !important;
}
.Profile_package_plan_head {
  font-weight: 600;
}
.notification_icon {
  font-size: 45px;
}
.notification_icon:hover {
  cursor: pointer;
}
.notification_heading_first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #b4b4b4;
  padding: 10px;
}
.cross-icon {
  font-size: 18px;
}
.cross-icon:hover {
  cursor: pointer !important;
}

.notification_heading_first p {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.notification_model {
  height: 100% !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #0e0e0e61;
}
.notification_model .modal-body {
  padding: 0 !important;
  background-color: #e1e1e1;
}
.notification_model .modal-dialog {
  width: 100% !important;
}

.notification_data {
  overflow-y: scroll;
  height: 500px;
  padding-top: 10px;
}
.notfication_list_first {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  position: relative;
  border-bottom: 2px solid #b4b4b4;
  padding-bottom: 10px;
  padding-top: 10px;
  align-items: center;
  justify-content: center;
}
.notfication_list_first:hover {
  background-color: #cccccc;
  cursor: pointer;
}

.Advertise_image_profile {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 2px;
  margin-left: 32px;
  border-radius: 50%;
}
.notification_count {
  margin-left: auto;
  background-color: #c70505;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notification_name_message :first-child {
  font-weight: 600;
}
.notification_count p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}
.notification_name_message p {
  margin: 0;
}
.no_notification_div {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.no_notification_div p {
  font-size: 34px;
  font-weight: 600;
  margin: 0;
}
.notification_number p {
  margin: 0 !important;
  font-weight: 600;
  font-size: 12px;
}
.notification_number {
  min-width: 20px;
  max-width: 40px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -4px;
  right: 0px;
}

.notification_icon_div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.message_count {
  min-width: 20px;
  max-width: 40px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}
.message_count p {
  margin: 0;
  display: flex;
  align-items: end;
  height: 100%;
}

/*Media Quries*/
@media screen and (max-width: 768px) and (min-width: 320px) {
  /* chat */
  .chat-side-container {
    width: 100% !important;
  }
  .user-messages {
    padding: 1rem !important;
  }
  .chat-right-side-container {
    width: 100%;
  }
  .chat-side-container-mobile {
    display: none;
  }
  .Chat-main-container {
    padding: 15px;
  }
  .chat-right-side-default-container {
    display: none !important;
  }
  .detail_nav_section .row {
    display: flex;
    flex-direction: column !important;
    gap: 10px;
  }
  .logo-image-div {
    width: 100% !important;
  }
  .main-store-adress {
    width: 100% !important;
  }

  .main-store-contact {
    width: 100% !important;
  }
  .profile-head .main_wrapper .profile_page {
    margin-bottom: 1rem;
  }
  .store-div-sec {
    width: 100% !important;
  }
  .detail_nav_section {
    width: 100%;
    float: left;
    display: block;
    padding: 20px 10px;
    box-shadow: 0 2px 10px -7px #000000;
    background-color: #8fa75dbd;
  }
  .main-store-div-head .container .colsm_detil .row .col-sm-4 {
    width: 100% !important;
  }
  .main-store-div-head .container {
    width: 100% !important;
  }
  .main-store-div-head .container .colsm_detil .row {
    row-gap: 0px !important;
  }
  .package-heading {
    margin-top: 0.5rem !important;
  }
  .newdiv {
    padding: 0 !important;
    width: 100%;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(1, 1fr);
  }
  .package-detail-11 {
    margin-top: 0 !important;
  }
  .store-div-parts {
    display: flex;
    flex-direction: column;
  }
  .store-country {
    width: 100% !important;
  }
  .store-submit {
    width: 40% !important;
    font-size: 20px !important;
    color: white !important;
    border: none !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
    padding: 10px !important;
    background-color: #295b65;
  }
  .store-submit-myStore {
    width: 40%;
    font-size: 20px !important;
    color: #295b65 !important;

    padding: 10px !important;
    font-weight: 600 !important;

    border-radius: 6px !important;
    background-color: white;
    border: 2px solid #295b65;
  }

  /* ----main-store css */
  .detail_nav_section .store_list_ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
    gap: 5px;
  }
  .detail_nav_section .store_list_ul li {
    text-align: center;
    margin-top: 15px;
    border: 0;
  }
  .contact_sport_dflex {
    display: flex;
    justify-content: left;
  }
  .detail_nav_section .contact_sport .sociel_list {
    justify-content: center;
  }
  .detail_nav_section .contact_sport h2 {
    margin-top: 15px;
  }
  /* ---------- */
  .main_header .market_logo .logo_jtc {
    width: 180px;
  }
  .dropdown-menu {
    left: unset !important;
  }
  /* .dropdown-menu-drop {
    drop: start !important;
  } */
  .my-div {
    margin-bottom: 0 !important;
  }
  .header-dropdown {
    color: black;
    margin-top: 0px !important;
    font-size: 12px !important;
    padding: 5px !important;
    height: 46px !important;
    background-color: white;
  }
  .price-main-div .label-country-div select {
    color: black;
    margin-top: 0px !important;
    font-size: 12px;
    padding: 5px !important;
    height: 40px !important;
    background-color: white;
  }
  .fav-main-div .main_wrapper .filer_heading .container {
    max-width: none !important;
    width: 100% !important;
  }
  .fav-main-div
    .main_wrapper
    .filer_heading
    .container
    .colsm_detil
    .row
    .col-sm-4 {
    width: 100% !important;
  }
  .main_header .market_logo .logo_car {
    width: 60px;
  }
  .pagination-botttom {
    display: flex !important;
    width: 100% !important;
    flex-direction: column !important;

    align-items: center !important;
    padding-top: 0px !important;
    gap: 15px !important;
  }
  .result-per-page {
    color: black;
    margin-top: 0px !important;
    font-size: 12px;
    padding: 5px !important;
    height: 40px !important;
    width: 25% !important;
  }
  .dashboard-main-div {
    padding-bottom: 15px;
  }
  .showing-para-pagination {
    width: 100% !important;
    text-align: center !important;
  }
  .result-per-page-div {
    display: flex !important;
    align-items: center !important;
    gap: 5px !important;
    width: 100% !important;
    text-align: center !important;

    justify-content: center !important;
  }
  .chat_ad_adver {
    font-size: 12px;
  }
  .chat_ad_prod {
    font-size: 12px;
  }
  .search_page .search_center .search_input {
    width: auto;
  }
  .search_page .search_center .search_btn {
    margin-top: 5px;
    margin-left: 3px;
  }
  .main_footer .justify-content-end {
    justify-content: center !important;
  }
  .filer_heading {
    padding-top: 20px;
    padding-bottom: 0 !important;
  }

  .search_page .search_center select {
    margin: 5px 0;
  }
  .data_page .data_Category ul {
    display: block;
  }
  .data_page .data_Category .list_fuel li {
    margin-top: 15px;
  }
  .data_page {
    margin-bottom: 0px;
    padding: 15px 0 0 0 !important;
  }
  .data_page .data_Category .brnd_label {
    width: 100%;
    margin-bottom: 2px !important;
  }
  .data_page .data_Category .type_input {
    width: 100%;
    line-height: 15px !important;
    padding: 5px;
    margin-top: 0 !important;
  }
  .filer_heading .colsm_detil {
    padding: 0px 0 20px 0;
  }
  .select_post_form .slct_post_list li a {
    font-size: 16px;
    width: auto;
    padding: 8px 15px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.46);
  }
  .data_page .data_Category .review_data .type_width {
    width: 100% !important;
  }
  .profile_page .profile_wraper {
    padding: 10px;
    width: auto;
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }
  .profile_page .profile_wraper ul li {
    font-size: 16px;
  }
  .no_notification_div p {
    font-size: 24px !important;
    font-weight: 600;
    margin: 0;
  }
  .search_page .main-form-home .form-input {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    gap: 5px !important;
  }
  .search_page .main-form-home .form-input .search-input {
    width: 92% !important;
    height: 65px !important;
    float: left !important;
    display: block !important;
    padding: 10px !important;
    background-color: #d9d9d9 !important;
    border: 0 !important;
    font-size: 16px !important;
    color: #000000 !important;
  }
  .home-butt-div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .search_page .main-form-home .form-input .input {
    width: 130px !important;
    margin-left: 0 !important;
    /* width: 50% !important; */
    padding: 0px 0px 0px 15px !important;
    float: left !important;
    background-color: #d9d9d9 !important;
    border: 0 !important;
    height: 65px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
  }
  .search_page .main-form-home .form-input .search-input-butt {
    float: left !important;
    width: 130px;
    height: 65px;
    border: 0 !important;
    background-color: #d9d9d9 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    display: flex !important;
    gap: 10px !important;
    align-items: center !important;
    color: black !important;
  }
  .mt-5 .mt-2 .upload_tabs .mt-3 .nav-tabs {
    display: flex !important;
    width: 100% !important;
  }
  .mt-5 .mt-2 .upload_tabs .mt-3 .nav-tabs .nav-item {
    width: 49% !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
  }
  .mt-5
    .mt-2
    .upload_tabs
    .mt-3
    .tab-content
    .postAdd_btn_2
    .label-location-input {
    width: 100% !important;
  }
  .navbar-expand-lg {
    width: 100% !important;
  }
  .navbar-brand {
    width: 50% !important;
  }
  .logo_jtc {
    width: 70% !important;
  }
  .logo_car {
    width: 50% !important;
  }
  .navbar-nav {
    width: 100% !important;
    display: flex !important;
    flex-wrap: wrap;
  }
  .cell_btn_Edit {
    width: 100% !important;
  }
  .label-country-div-1 {
    padding-left: 0 !important;
    width: 100%;
  }
  .navbar-collapse .navbar-nav .nav-item {
    padding-left: 0 !important;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
  }
  .footer-main {
    width: 100%;
    padding: 20px 0;
    background-color: #fff;
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
    bottom: 0;
  }
  .modal-google {
    padding: 0 10px;
  }
  .modal-body-radio label {
    text-indent: -20px !important;
    padding-left: 20px !important;
  }
  .modal-google .modal-dialog {
    height: 100% !important;
    margin: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .modal_header_google .modal-dialog {
    height: 100% !important;
    margin: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .modal_body_content .body_popup h1 {
    font-size: 23px;
    line-height: 24px;
    font-weight: bold;
  }
  .signin-butt-google {
    margin-right: 0 !important;
  }
  .navbar-collapse {
    width: 100% !important;
  }
  .modal_body_content .body_popup {
    padding-top: 32px !important;
    padding: 0;
  }

  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .navbar-nav .dropdown-menu {
    text-align: center !important;
  }
  .nav-item a.inactive {
    text-align: center !important;
  }
  /* filter page mobile responsive */
  .main_wrapper .filer_heading .container .colsm-12 .row {
    margin-bottom: 20px !important;
  }
  .filer_heading .cars_deal .cars_deal_wrap h5 {
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
  }
  .upload-store-image-grid {
    width: 50% !important;
  }
  .filer_heading .cars_deal .cars_deal_wrap p {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .filer_heading .cars_deal .cars_deal_wrap {
    padding: 20px 15px 20px !important;
    position: relative !important;
  }
  .filer_heading .cars_deal .cars_deal_wrap h4 {
    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 27px !important;
  }
  .main-store-head {
    padding: 20px 0px !important;
  }
  .result-div-page {
    padding-top: 20px !important;
  }
  .filer_heading .custom_accordian .accordion-body label {
    font-size: 14px !important;
    font-weight: 600 !important;
  }
  .filer_heading .custom_accordian .accordion-button {
    font-size: 16px !important;
    color: #000000 !important;
    font-weight: 600 !important;
  }
  /* ------home page mobile responsive */
  .main-form-home h1 {
    font-size: 25px !important;
    line-height: 40px !important;
    font-weight: 700 !important;
  }
  /* -----detail page mobile responsive */
  .details-div {
    width: 100% !important;
  }
  .book_free {
    margin-top: 1rem !important;
  }
  .image-slick-main {
    min-height: 230px !important;
    max-height: 230px !important;
    object-fit: contain !important;
  }
  .align-items-center {
    justify-content: center;
    align-items: center !important;
  }
  .image-slick-parts {
    min-height: 230px !important;
    max-height: 230px !important;
    object-fit: contain !important;
  }
  .image-slick-accesories {
    min-height: 230px !important;
    max-height: 230px !important;
    object-fit: contain !important;
  }
  .filer_heading .detals_heading {
    margin-bottom: 0 !important;
  }
  .overview-tab .mt-3 .active .nav-tabs {
    justify-content: flex-start !important;
    margin: 0 !important;
  }
  .overview-tab .mt-3 .active .nav-tabs li {
    margin: 0 !important;
  }

  .overview_heading {
    font-size: 25px !important;
    line-height: 25px !important;
    font-weight: 600 !important;
  }
  .price-over-div {
    display: flex;
    gap: 20px;
    width: 98%;
    justify-content: space-between;
  }
  .logo-icon-p {
    margin: 0;
    font-size: 12px !important;
    font-weight: 600;
  }
  .logo-icon-detail {
    font-size: 15px !important;
    margin-right: 10px;
  }
  .store-country-select {
    color: black;
    margin-top: 0px !important;
    font-size: 12px;
    padding: 5px !important;
    height: 40px !important;
  }
  .overview-name-data {
    margin: 0;
    font-size: 12px !important;
    font-weight: 600;
  }
  .book_free {
    color: #f75d34 !important;
    font-weight: bold !important;
    font-size: 16px !important;
    display: block !important;
    margin-top: 10px !important;
  }
  .main_wrapper .filer_heading .container .colsm-12 .row .col-sm-9 .row {
    margin-bottom: 0px !important;
  }
  .main_wrapper .filer_heading .container .colsm-12 .row {
    margin-bottom: 0px !important;
  }
  .main-filter-row {
    padding-bottom: 20px !important;
  }
  .result-pagination {
    margin-bottom: 0px !important;
  }
  .location-div {
    width: 100% !important;
  }
  .colsm_detil .detail_cars_cvt .cvt_heding {
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 25px !important;
  }
  .vehicle-detail-p {
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .colsm_detil .detail_cars_cvt .detail_view_benifit {
    font-size: 18px !important;
    font-weight: bold !important;
    line-height: 27px !important;
  }
  .colsm_detil .detail_cars_cvt .detail_view_benifit a {
    font-size: 12px !important;
  }
  .price-main-div-head h5 {
    font-size: 15px;
  }
  .country-label {
    font-size: 14px !important;
  }
  .countryType {
    color: black;
    margin-top: 0px !important;
    font-size: 12px;
    padding: 5px !important;
    height: 40px !important;
  }
  .main_details {
    padding-bottom: 15px !important;
  }
  /* --------detail tab modal mobile responsive */
  .Term_Modal .modal-title {
    font-size: 14px !important;
  }
  .Term_Modal .modal-dialog {
    height: 90% !important;
  }
  .Term_Modal .modal-dialog {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Term_Modal .modal-dialog .modal-content .modal-body ol {
    padding: 0 !important;
  }
  .Term_Modal .modal-dialog .modal-content .modal-body ol li {
    font-size: 11px !important;
  }
  .Term_Modal .modal-dialog .modal-content .modal-body {
    padding: 10px !important;
  }
  .Term_Modal .modal-dialog .modal-content .modal-footer {
    padding: 10px !important;
  }
  /* ------dashboard mobile responsive */
  .dashboard_start {
    padding: 20px 0 !important;
  }
  .dashboard-image {
    min-width: 170px !important;
    min-height: 90px !important;
    max-width: 170px !important;
    max-height: 90px !important;
    object-fit: cover !important;
  }
  .chat-input-div {
    height: 10% !important;
  }
  .main_wrapper .filer_heading .container .colsm_detil .row {
    padding-bottom: 0px;
  }
  .dashboard_table {
    width: 100% !important;
  }
  .dashboard_table .d-block tbody tr td {
    font-size: 12px !important;
  }
  .dashboard-butt {
    width: 118px !important;
    font-size: 12px !important;
  }
  .disableButt {
    width: 118px !important;
    font-size: 12px !important;
  }
  .disableButt-republish {
    width: 118px !important;
    font-size: 12px !important;
  }
  .disableButt-edit {
    width: 118px !important;
    font-size: 12px !important;
  }

  .image-modal-dashboard .modal-dialog {
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .image-modal-dashboard .modal-dialog .modal-content {
    width: 93% !important;
    height: unset;
  }
  .delete-image-icon-dashboard {
    color: black;
    font-size: 19px !important;
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -9px;
    background-color: black;
    color: white;
    border-radius: 50%;
    height: 19px;
    width: 20px;
    padding: 5px;
  }
  .dashboard-modal {
    padding: 0 !important;
  }
  .dashboard-modal .modal-dialog {
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* ------sell page mobile responsive */
  .main_border {
    width: 92% !important;
    padding: 10px;
  }
  .main-all-div-post .main_border .data_Category h2 {
    font-size: 18px !important;
  }
  .data_page .data_Category .selct_hding {
    font-size: 16px !important;
    margin-bottom: 10px !important;
  }
  .data_page .data_Category .brnd_label {
    font-size: 15px !important;
  }

  .month-year-div {
    display: flex;
    flex-direction: column !important;
  }
  .tabs_main {
    margin-bottom: 0 !important;
  }
  .month-year-div .mt-5 select {
    width: 100% !important;
  }
  .Registration-Year-input {
    font-size: 12px !important;
    padding: 5px !important;
    line-height: 15px;
  }
  .radio---buttons input {
    margin-right: 0 !important;
  }
  .fuel-input-select {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .fuel-input-select input {
    margin-right: 0 !important;
  }
  .select-transmisson {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .cell_btn {
    background-color: #000 !important;
    border-radius: 4px !important;
    color: #fff !important;
    font-size: 20px !important;
    font-weight: 700 !important;
    height: 46px !important;
    line-height: 46px !important;
    text-align: center !important;
    width: 100% !important;

    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    gap: 5px !important;
  }
  .owner-select {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .parts-input-type {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .buttons input {
    margin-right: 0 !important;
  }
  .owner-select input {
    font-size: 14px;
    padding: 5px;
    width: 48%;
    height: 100% !important;
  }
  .select-transmisson input {
    font-size: 14px;
    padding: 5px;
    width: 48%;
    height: 100% !important;
  }
  .fuel-input-select input {
    font-size: 14px;
    padding: 5px;
    width: 48%;
    height: 100% !important;
  }
  .month-year-div .mt-5 {
    width: 100% !important;
    margin-bottom: 1.5rem;
  }
  .label-upload-image {
    width: 100% !important;
  }
  #upload-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100% !important;
    row-gap: 20px;
    padding-bottom: 15px;
  }

  .upload-parts-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100% !important;
    row-gap: 20px;
    padding-bottom: 15px;
    column-gap: 10px !important;
  }
  #image-radio-buttons {
    height: 100%;
    appearance: none;
    cursor: pointer;
    transition: all 0.1s;
    border: 1px solid #0000003d;
    font-size: 18px;
    border-radius: 7px;
    width: 100%;
    margin-right: 15px;
  }
  .radio--buttons input {
    margin-right: 0 !important;
  }
  .image-parts-radio-buttons {
    height: 100%;
    appearance: none;
    cursor: pointer;
    transition: all 0.1s;
    border: 1px solid #0000003d;
    font-size: 18px;
    border-radius: 7px;
    margin-right: 15px;
    width: 100%;
  }
  .select-icons-image {
    position: relative;
    z-index: 2;
    top: 25px;
  }
  .delete-image-icon {
    color: black;
    font-size: 19px !important;
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: black;
    color: white;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    padding: 3px;
  }
  .delete-part-image-icon {
    color: black;
    font-size: 19px !important;
    cursor: pointer;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: black;
    color: white;
    border-radius: 50%;
    height: 14px;
    width: 14px;
    padding: 3px;
  }
  #upload-images {
    min-height: 80px;
    max-height: 80px;
    width: 100%;

    object-fit: cover;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    border-radius: 7px;
  }
  .chat-messages {
    display: flex !important;
    flex-direction: column !important;
    height: 25% !important;
    padding: 10px !important;
    gap: 5px !important;
    align-items: flex-start !important;
  }
  .main-message-container {
    height: 65% !important;
  }
  .chat-side-container {
    margin: 0 !important;
  }
  .chat-right-side-container {
    margin: 0 !important;
  }
  .upload-part-images {
    min-height: 80px;
    max-height: 80px;
    width: 100% !important;
    object-fit: cover;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    border-radius: 7px;
  }
  .upload-store-images {
    min-height: 80px;
    max-height: 80px;
    width: 100% !important;
    object-fit: cover;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    border-radius: 7px;
  }
  .mt-2 .upload_tabs .nav-tabs {
    display: flex;
    width: 100% !important;
  }
  .mt-2 .upload_tabs .nav-tabs li {
    width: 49%;
  }
  .data_page .data_Category ul li {
    margin: 0 !important;
    padding: 0 !important;
  }
  .postAdd_btn_1 .mt-2 .upload_tabs .nav-tabs .nav-item .nav-link {
    font-size: 15px !important;
  }
  .postAdd_btn_2
    .postAdd-2
    .mt-3
    .mt-5
    .mt-2
    .upload_tabs
    .nav-tabs
    .nav-item
    .nav-link {
    font-size: 15px !important;
  }
  .postAdd-3
    .mt-3
    .mt-5
    .mt-2
    .upload_tabs
    .mt-3
    .nav-tabs
    .nav-item
    .nav-link {
    font-size: 15px !important;
  }
  .location-data {
    font-size: 14px !important;
  }
  .data_Category .sbmit_btn_end {
    width: 50%;
    font-size: 20px !important;
    color: white !important;
    border: none !important;
    padding: 10px !important;
    font-weight: 600 !important;
    border-radius: 6px !important;
  }

  .label-parts-upload-image {
    width: 100% !important;
  }
  .label-store-upload-image {
    width: 100% !important;
  }
  .main-all-div-post {
    padding-bottom: 20px;
  }
  .main-all-div-post .main_border .data_Category .mb-4 select {
    color: black;
    margin-top: 0px !important;
    font-size: 12px;
    padding: 5px !important;
    height: 40px !important;
  }
  .main-all-div-post .main_border .data_Category .month-year-div .mt-5 select {
    color: black;
  }
  .list-location select {
    color: black;
  }
  .slick-dots li button {
    display: none;
  }
  .slick-initialized .slick-next {
    right: 0px;
  }
  .slick-initialized .slick-prev {
    display: block !important;
    left: 0px;
    z-index: 999;
    margin-left: 0px !important;
  }
  .slick-next:before,
  .slick-prev:before {
    font-size: 23px;
    color: black;
  }
  .main_wrapper .filer_heading .container .colsm_detil .row .col-sm-5 {
    padding-top: 15px !important;
  }
  .main_wrapper .filer_heading .container .colsm-12 .row {
    padding-bottom: 0px;
  }
  .colsm_detil .detail_cars_cvt {
    width: 100%;
    float: left;
    display: block;
    padding: 15px 5px 0px 5px;
  }

  .heading-price-money {
    font-weight: 700;
    font-size: 16px;
  }

  .main-section-homePage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-head .main_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  .main-all-div-post .main_border .data_Category h2 {
    font-size: 30px !important;
  }
  .fav-main-div .main_wrapper .filer_heading .container {
    max-width: none !important;
    width: 100% !important;
  }
  .scroll-hello {
    height: auto;
    max-height: 152px !important;
    overflow-y: auto;
  }
  .fav-main-div
    .main_wrapper
    .filer_heading
    .container
    .colsm_detil
    .row
    .col-sm-4 {
    width: 50% !important;
  }
  .data_page .data_Category .selct_hding {
    font-size: 20px !important;
    margin-bottom: 10px !important;
  }
  .data_page .data_Category .brnd_label {
    font-size: 18px !important;
  }
  .data_page .data_Category .type_input {
    margin-top: 0px !important;
    font-size: 18px;
    padding: 0 0 0 9px !important;
    height: 60px !important;
  }

  .Registration-Year-input {
    font-size: 20px !important;
    padding: 14px !important;
    line-height: 15px;
  }
  .main-section-homePage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .profile-head .main_wrapper {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .navbar .container .navbar-toggler {
    font-size: 30px;
  }
  .navbar
    .container
    .navbar-toggler
    .show
    .nav-item
    .dropdown.dropdown-toggle
    img {
    height: 60px;
    width: 60px;
  }
  .filer_heading .custom_accordian .accordion-body label {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .filer_heading .cars_deal .cars_deal_wrap h4 {
    font-size: 30px !important;
    font-weight: bold !important;
    line-height: 27px !important;
  }
  .filer_heading .cars_deal .cars_deal_wrap span {
    font-size: 15px;
    color: #fff;
    background-color: #000000;
    padding: 8px 10px;
    border-radius: 5px;
    margin-left: 10px;
  }
  .filer_heading .cars_deal .cars_deal_wrap .regular_off {
    font-size: 20px;
    line-height: 22px;
    color: #f00;
    font-weight: 600;
    background-color: #e7e7e7;
    padding: 12px 14px;
    border-radius: 5px;
    display: inline-block;
  }
  .filer_heading .cars_deal .cars_deal_wrap .likes_btn {
    font-size: 45px;
    color: red;
  }
  .filer_heading .detals_heading {
    font-size: 30px;
  }
  .vehicle-detail-p {
    font-size: 17px !important;
    line-height: 21px !important;
  }
  .sentMessage p {
    width: fit-content !important;
    background: #ccc;

    padding: 2px 10px;

    border-radius: 0 8px 8px 8px;
    max-width: 190px !important;
    word-wrap: break-word !important;
  }
  .receiveMessage p {
    width: fit-content !important;
    background: #ccc;

    padding: 2px 10px;

    border-radius: 8px 0px 8px 8px;
    max-width: 190px !important;
    word-wrap: break-word !important;
  }
  .colsm_detil .detail_cars_cvt .detail_view_benifit {
    font-size: 22px !important;
    font-weight: bold !important;
    line-height: 27px !important;
  }
  .colsm_detil .detail_cars_cvt .detail_regular_price {
    margin-top: 10px;
    font-size: 18px;
    line-height: 22px;
    color: #f00;
    font-weight: 600;
    background-color: #e7e7e7;
    padding: 10px 12px;
    border-radius: 5px;
    display: inline-block;
  }
  .price-main-div-head h5 {
    font-size: 20px;
  }
  .country-label {
    font-size: 18px !important;
  }
  .heading-price-money {
    font-weight: 700;
    font-size: 16px;
  }
  .logo-icon-p {
    margin: 0;
    font-size: 18px !important;
    font-weight: 600;
  }
  .overview-name-data {
    margin: 0;
    font-size: 16px !important;
    font-weight: 600;
  }
  .countryType {
    width: 100%;
    display: block;
    font-size: 20px;
    border: 1px solid #0000003d;
    line-height: 26px;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
  }
  .colsm_detil .detail_cars_cvt {
    width: 100%;
    float: left;
    display: block;
    padding: 20px 12px;
  }
  .filer_heading .cars_deal .cars_deal_wrap h5 {
    font-size: 25px !important;
    font-weight: 600 !important;
    line-height: 25px !important;
  }
  .filer_heading .cars_deal .cars_deal_wrap p {
    font-size: 18px !important;
    line-height: 30px !important;
  }
  .search_page .main-form-home .form-input .search-input-butt {
    float: left !important;
    width: 130px;
    height: 75px;
    border: 0 !important;
    background-color: #d9d9d9 !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    display: flex !important;
    gap: 10px !important;
    align-items: center !important;
    color: black !important;
  }
  .select-transmisson input {
    font-size: 16px imo !important;
    padding: 9px 0 9px 9px !important;
    width: 17% !important;
    height: 100% !important;
  }
  .search_page .main-form-home .form-input .input {
    width: 130px !important;
    margin-left: 0 !important;
    padding: 0px 0px 0px 15px !important;
    float: left !important;
    background-color: #d9d9d9 !important;
    border: 0 !important;
    height: 75px !important;
    font-size: 20px !important;
    font-weight: 600 !important;
  }
  .search_page .main-form-home .form-input .search-input {
    width: 92% !important;
    height: 75px !important;
    float: left !important;
    display: block !important;
    padding: 10px !important;
    background-color: #d9d9d9 !important;
    border: 0 !important;
    font-size: 20px !important;
    color: #000000 !important;
  }
  .main-form-home h1 {
    font-size: 35px !important;
    line-height: 40px !important;
    font-weight: 700 !important;
  }
  .filer_heading .custom_accordian .accordion-body label {
    font-size: 14px !important;
    font-weight: 600;
  }
  .col-sm-4 {
    width: 100% !important;
  }
  .col-sm-3 {
    width: 35% !important;
  }
  .details-div {
    width: 100% !important;
  }
  .image-slick-main {
    min-height: 400px !important;
    max-height: 400px;
    object-fit: contain;
  }
  .image-slick-parts {
    min-height: 400px;
    max-height: 400px;
    object-fit: contain;
  }
  .image-slick-accesories {
    min-height: 400px;
    max-height: 400px;
    object-fit: contain;
  }
  .container {
    width: 100% !important;
  }
  .profile_wraper {
    width: 100% !important;
  }
  .main_wrapper .profile_page .container.profile_wraper .row {
    width: 100% !important;
    display: flex;
    align-items: center;
  }

  .slick-dots li button {
    display: none;
  }
  .main_wrapper .profile_page .container.profile_wraper .row .d-flex {
    width: 22% !important;
    justify-content: center !important;
  }
  .main_wrapper .profile_page .container .profile_wraper .row .d-flex {
    width: 40% !important;
    justify-content: center !important;
  }
  .main_wrapper .profile_page .container .profile_wraper .row .name-email-div {
    width: 60% !important;
    justify-content: center !important;
  }
  .colsm_detil .detail_cars_cvt .cvt_heding {
    font-size: 25px !important;
    font-weight: 600;
  }

  #upload-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 50% !important;
    row-gap: 20px;
    padding-bottom: 15px;
  }
  .upload-parts-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 50% !important;
    row-gap: 20px;
    padding-bottom: 15px;
  }
  .cars-fav-deal {
    min-height: 545px !important;
    max-height: 545px !important;
  }

  .imgclassName {
    width: 100% !important;
    height: 300px !important;
    object-fit: cover !important;
  }
  .col-sm-9 {
    width: 65% !important;
    margin-top: -2.5% !important;
  }
  .list_fuel {
    display: flex !important;
  }
  .parts-input-type input {
    font-size: 14px;
    padding: 9px 0 9px 9px;
    width: 48%;
    height: 100% !important;
  }
  .navbar-nav {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    flex-wrap: unset !important;
  }
  .cell_btn_Edit {
    width: 225px !important;
  }

  /* .dropdown-menu {
    left: -67px !important;
  } */
  .header-main-div .container {
    width: 100% !important;
    max-width: unset !important;
  }
  .data_page .data_Category ul {
    display: flex !important;
  }
  .navbar-toggler:focus {
    box-shadow: none !important;
  }
  .mt-2 .upload_tabs .nav-tabs {
    justify-content: space-evenly !important;
    border-bottom: 1px solid #dee2e6 !important;
  }
  .month-year-div .mt-5 {
    margin-top: 0 !important;
    width: 50% !important;
  }
  .main_wrapper
    .filer_heading
    .container
    .colsm_detil
    .row
    .col-sm-5
    .main_details
    .detail_cars_cvt
    .detail_step
    .row
    .col-sm-4 {
    width: 33% !important;
  }
  .main_wrapper .filer_heading .container .colsm_detil .row .col-sm-5 {
    padding-top: 26px !important;
  }
  .Term_Modal .modal-dialog {
    width: 80% !important;
  }
  #upload-images {
    width: 100% !important;
    min-height: 135px;
    max-height: 135px;
    object-fit: cover;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    border-radius: 7px;
  }
  .upload-part-images {
    min-height: 135px;
    width: 100% !important;
    object-fit: cover;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    max-height: 135px;
    border-radius: 7px;
  }
  .upload-store-images {
    min-height: 135px;
    width: 100% !important;
    object-fit: cover;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.7);
    max-height: 135px;
    border-radius: 7px;
  }
  #upload-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100% !important;
    row-gap: 20px;
    padding-bottom: 15px;
    column-gap: 20px;
  }

  .upload-parts-image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100% !important;
    row-gap: 20px;
    padding-bottom: 15px;
    column-gap: 10px !important;
  }
  #image-radio-buttons {
    height: 100%;
    appearance: none;
    cursor: pointer;
    transition: all 0.1s;
    border: 1px solid #0000003d;
    font-size: 18px;
    border-radius: 7px;
    margin-right: 15px;
    width: 100%;
  }
  .image-parts-radio-buttons {
    height: 100%;
    appearance: none;
    cursor: pointer;
    transition: all 0.1s;
    border: 1px solid #0000003d;
    font-size: 18px;
    border-radius: 7px;
    margin-right: 15px;
  }
  .select-icons-image {
    position: relative;
    z-index: 2;
  }
  .delete-image-icon {
    color: black;
    font-size: 19px !important;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -11px;
    background-color: black;
    color: white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 5px;
  }
  .delete-part-image-icon {
    color: black;
    font-size: 19px !important;
    cursor: pointer;
    position: absolute;
    top: -10px;
    right: -11px;
    background-color: black;
    color: white;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    padding: 5px;
  }
  .main-all-div-post .main_border .data_Category .mb-4 select {
    color: black;
  }
  .main-all-div-post .main_border .data_Category .month-year-div .mt-5 select {
    color: black;
  }
  .list-location select {
    color: black;
  }
  .fuel-input-select input {
    font-size: 15px;
    padding: 9px 0 9px 9px;
    width: 18%;
    height: 100% !important;
  }
  .owner-select input {
    font-size: 14px;
    padding: 9px 0 9px 9px;
    width: 18%;
    height: 100% !important;
  }
  .parts-input-type {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
  .footer-main {
    width: 100%;
    padding: 20px 0;
    background-color: #fff;
    box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
    -webkit-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
    -moz-box-shadow: 0px -1px 5px 0px rgba(0, 0, 0, 0.17);
    bottom: 0;
  }
  .postAdd_btn_1 .mt-2 .upload_tabs .nav-tabs .nav-item .nav-link {
    font-size: 20px !important;
  }
  .postAdd-3
    .mt-3
    .mt-5
    .mt-2
    .upload_tabs
    .mt-3
    .nav-tabs
    .nav-item
    .nav-link {
    font-size: 20px !important;
  }
  .postAdd_btn_2
    .postAdd-2
    .mt-3
    .mt-5
    .mt-2
    .upload_tabs
    .nav-tabs
    .nav-item
    .nav-link {
    font-size: 20px !important;
  }
  .dashboard_table .d-block tbody tr td {
    font-size: 17px !important;
  }
  .profile-head .main_wrapper .navbar .container .navbar-collapse .cell_btn {
    height: 46px !important;
    width: 200px !important;
  }
  .navbar-collapse .navbar-nav .nav-item {
    width: unset !important;
  }
  .dropdown-menu {
    right: 0 !important;
    left: auto !important;
  }
  .cell_btn {
    width: 200px !important;
  }
  .profile-head
    .main_wrapper
    .navbar
    .container
    .navbar-collapse
    .cell_btn
    .dropdown-toggle
    img {
    height: 60px;
    width: 60px;
  }
}
